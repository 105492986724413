.contacto {
  min-height: 90vh;
  display: flex;
  align-items: center;

  &__form {
    padding: 10% 0;

    @include mq(sm) {
      padding: 7% 30px;
    }

    &__group {
      width: 100%;
    }

    &__input {
      border: none;
      border: 1px solid $lightGray;
      margin-bottom: 20px;
      width: 100%;
      padding-left: 10px;
      outline: none;
      font-family: 'Seat', sans-serif;
      height: 50px;
      background: linear-gradient(to bottom, $lightestGray, $white);
      line-height: 50px;

      &--textarea {
        min-height: 150px;
      }
    }
    
    
    &__submit {
      display: block;
      background: $blue;
      border: none;
      border-radius: 5px;
      padding: 15px 0;
      text-align: center;
      font-weight: 700;
      color: $white;
      font-size: 14px;
      width: 200px;
      cursor: pointer;
    }
  }
}