.header {

    $h: &;

    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 1000;
    background: $white;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2vh 20px;

        @include mq(md) {
            padding: 2vh 0;
        }
    }

    &__logo {
        height: 6vh;
        z-index: 2000;

        @include mq(md) {
            margin-left: 40px;
            height: 6vh;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__nav {
        @include trans;
        opacity: 0;
        overflow: hidden;
        width: 0;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        transform: translateX(-100%);

        @include mq(md) {
            opacity: 1;
            position: relative;
            height: auto;
            justify-content: flex-end;
            transform: translateX(0);
        }

        &__list {
            @include mq(md) {
                display: flex;
            }

            li {
                &:not(:last-child) {
                    margin-bottom: 20px;

                    @include mq(md) {
                        margin-bottom: 0;
                    }
                }

                @include mq(md) {
                    margin-right: 40px;
                }

                a {
                    text-decoration: none;
                    color: $darkGray;
                    font-size: 18px;
                                        
                    @include mq(md) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 21px;
        z-index: 1000;

        @include mq(md) {
            display: none;
        }

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            opacity: 1;
            overflow: visible;
            width: 100%;
            transform: translateX(0);
       }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
