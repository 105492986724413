@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'VW Headline';
  src: url('/fonts/VWHeadlineOT-Book.eot');
  src: url('/fonts/VWHeadlineOT-Book.eot?#iefix') format('embedded-opentype'),
      url('/fonts/VWHeadlineOT-Book.woff2') format('woff2'),
      url('/fonts/VWHeadlineOT-Book.woff') format('woff'),
      url('/fonts/VWHeadlineOT-Book.ttf') format('truetype'),
      url('/fonts/VWHeadlineOT-Book.svg#VWHeadlineOT-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Volkswagen Semi';
  src: url('/fonts/VolkswagenSemi.eot');
  src: url('/fonts/VolkswagenSemi.eot?#iefix') format('embedded-opentype'),
      url('/fonts/VolkswagenSemi.woff2') format('woff2'),
      url('/fonts/VolkswagenSemi.woff') format('woff'),
      url('/fonts/VolkswagenSemi.ttf') format('truetype'),
      url('/fonts/VolkswagenSemi.svg#VolkswagenSemi') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Volkswagen Headline';
  src: url('/fonts/VolkswagenHeadline.eot');
  src: url('/fonts/VolkswagenHeadline.eot?#iefix') format('embedded-opentype'),
      url('/fonts/VolkswagenHeadline.woff2') format('woff2'),
      url('/fonts/VolkswagenHeadline.woff') format('woff'),
      url('/fonts/VolkswagenHeadline.ttf') format('truetype'),
      url('/fonts/VolkswagenHeadline.svg#VolkswagenHeadline') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VW Headline';
  src: url('/fonts/VWHeadlineOT-Semibold.eot');
  src: url('/fonts/VWHeadlineOT-Semibold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/VWHeadlineOT-Semibold.woff2') format('woff2'),
      url('/fonts/VWHeadlineOT-Semibold.woff') format('woff'),
      url('/fonts/VWHeadlineOT-Semibold.ttf') format('truetype'),
      url('/fonts/VWHeadlineOT-Semibold.svg#VWHeadlineOT-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'VW Headline';
  src: url('/fonts/VWHeadlineOT-Black.eot');
  src: url('/fonts/VWHeadlineOT-Black.eot?#iefix') format('embedded-opentype'),
      url('/fonts/VWHeadlineOT-Black.woff2') format('woff2'),
      url('/fonts/VWHeadlineOT-Black.woff') format('woff'),
      url('/fonts/VWHeadlineOT-Black.ttf') format('truetype'),
      url('/fonts/VWHeadlineOT-Black.svg#VWHeadlineOT-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

